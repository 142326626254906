import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Latest, WindReport } from './DawgWind.tsx';

import './WindPanel.scss';

@observer export default class WindPanel extends React.Component<{}, {}> {
  constructor(props: any) {
    super(props);
  }

  render() {
    let counter = 0;

    return (
      <div className='windpanel'>
        <div className='windpanel-header windpanel-station'>Station</div>
        <div className='windpanel-header windpanel-dir'>Dir</div>
        <div className='windpanel-header windpanel-speed'>Sp</div>
        <div className='windpanel-header windpanel-gust'>Gu</div>
        <div className='windpanel-header windpanel-age'>Age</div>
        <div className='windpanel-header windpanel-status' />
        <div className='windpanel-rule' />

        <WindPanelRow rownum={counter++} label='SFO' provider='mesowest' station='KSFO' />
        <WindPanelRow rownum={counter++} label='PA Airport' provider='mesowest' station='KPAO' />
        <WindPanelRow rownum={counter++} label='HMB Airport' provider='mesowest' station='KHAF' />
        <WindPanelRow rownum={counter++} label='Moffett' provider='mesowest' station='KNUQ' />
        <WindPanelRow rownum={counter++} label='Travis AFB' provider='mesowest' station='KSUU' />
        <WindPanelRow rownum={counter++} label='OB/GGNRA' provider='wunderground' station='KCASANFR107' />
        <WindPanelRow rownum={counter++} label='Angel Island' provider='omrdar' station='Angel Is' />
        <WindPanelRow rownum={counter++} label='GG Bridge' provider='omrdar' station='GG Bridge' />
        <WindPanelRow rownum={counter++} label='Crissy West' provider='mesowest' station='FTPC1' />
        <WindPanelRow rownum={counter++} label='GG YachtClub' provider='wunderground' station='KCASANFR1229' />
        <WindPanelRow rownum={counter++} label='TI Bldg 1' provider='wunderground' station='KCASANFR1231' />
        <WindPanelRow rownum={counter++} label='Younger Lagn' provider='mesowest' station='UCYL' />
        <WindPanelRow rownum={counter++} label='Pt Reyes LH' provider='omrdar' station='Pt Reyes' />
        <WindPanelRow rownum={counter++} label='Reyes Buoy' provider='mesowest' station='PRYC1' />
        <WindPanelRow rownum={counter++} label='Bodega Buoy' provider='mesowest' station='46013' />
        <WindPanelRow rownum={counter++} label='GG Buoy' provider='mesowest' station='46026' />
        <WindPanelRow rownum={counter++} label='HMB Buoy' provider='mesowest' station='46012' />
        <WindPanelRow rownum={counter++} label='Waddell Buoy' provider='mesowest' station='46042' />
      </div>
    );
  }
}

interface IWindPanelRowProps {
  label: string;
  provider: string;
  station: string;
  rownum: number;
}

@observer class WindPanelRow extends React.Component<IWindPanelRowProps, {}> {
  @observable loading = true;
  @observable counter = 0;
  @observable report: WindReport;

  intervalHandle: any = undefined;

  constructor(props: IWindPanelRowProps) {
    super(props);
    this.loading = true;
    this.fetchLatest();
  }

  @action componentDidMount() {
    if (this.loading) {
      this.intervalHandle = setInterval(() => {
        this.counter++;
      }, 50);
    }
  }

  @action async fetchLatest() {
    try {
      const report = await Latest(this.props.provider, this.props.station);
      this.loading = false;
      this.report = report;
      clearInterval(this.intervalHandle);
    } catch (err) {
      this.loading = false;
      clearInterval(this.intervalHandle);
    }
  }

  render() {
    const evenCls = this.props.rownum % 2 ? 'windpanel-evenrow' : '';

    if (this.loading) {
      const spinner = '|/-\\';
      return [
        <div key='1' className={`windpanel-station windpanel-loading ${evenCls}`}>{this.props.label}</div>,
        <div key='2' className={`windpanel-dir windpanel-loading ${evenCls}`} />,
        <div key='3' className={`windpanel-speed windpanel-loading ${evenCls}`} />,
        <div key='4' className={`windpanel-gust windpanel-loading ${evenCls}`} />,
        <div key='5' className={`windpanel-age windpanel-loading ${evenCls}`} />,
        (
            <div key='6' className={`windpanel-status windpanel-loading ${evenCls}`}>
              {spinner[this.counter % spinner.length]}
            </div>
        ),
      ];
    }

    if (this.report === null || typeof this.report === 'undefined' || !this.report.valid) {
      return [
        <div key='1' className={`windpanel-station windpanel-error ${evenCls}`}>{this.props.label}</div>,
        <div key='2' className={`windpanel-dir windpanel-error ${evenCls}`} />,
        <div key='3' className={`windpanel-speed windpanel-error ${evenCls}`} />,
        <div key='4' className={`windpanel-gust windpanel-error ${evenCls}`} />,
        <div key='5' className={`windpanel-age windpanel-error ${evenCls}`} />,
        <div key='6' className={`windpanel-status windpanel-error ${evenCls}`}>x</div>,
      ];
    }

    const windMphStr = typeof this.report.windMph === 'undefined' ? '' : Math.round(this.report.windMph).toString();
    const gustMphStr = typeof this.report.gustMph === 'undefined' ? '' : Math.round(this.report.gustMph).toString();

    const label = typeof this.report.stationLink === 'undefined' ? this.props.label : (
      <a href={this.report.stationLink}>{this.props.label}</a>
    );

    if (this.report.isStale()) {
      return [
        <div key='1' className={`windpanel-station windpanel-stale ${evenCls}`}>{label}</div>,
        <div key='2' className={`windpanel-dir windpanel-stale ${evenCls}`}>{this.report.windDir}</div>,
        <div key='3' className={`windpanel-speed windpanel-stale ${evenCls}`}>{windMphStr}</div>,
        <div key='4' className={`windpanel-gust windpanel-stale ${evenCls}`}>{gustMphStr}</div>,
        <div key='5' className={`windpanel-age windpanel-stale ${evenCls}`}>{this.report.age}</div>,
        <div key='6' className={`windpanel-status windpanel-stale ${evenCls}`}>!</div>,
      ];
    } else {
      return [
        <div key='1' className={`windpanel-station ${evenCls}`}>{label}</div>,
        <div key='2' className={`windpanel-dir ${evenCls}`}>{this.report.windDir}</div>,
        <div key='3' className={`windpanel-speed ${evenCls}`}>{windMphStr}</div>,
        <div key='4' className={`windpanel-gust ${evenCls}`}>{gustMphStr}</div>,
        <div key='5' className={`windpanel-age ${evenCls}`}>{this.report.age}</div>,
        <div key='6' className={`windpanel-status windpanel-ok ${evenCls}`} />,
      ];
    }
  }
}

function pad(padding: string, str: string, padLeft: boolean) {
  if (typeof str === 'undefined') {
    return padding;
  }
  if (padLeft) {
    return (padding + str).slice(-padding.length);
  } else {
    return (str + padding).substring(0, padding.length);
  }
}
