import * as React from 'react';
import * as ReactDOM from 'react-dom';

import PressureDiff from './PressureDiff.tsx';
import TAFWidget from './TAF.tsx';
import WindPanel from './WindPanel.tsx';

ReactDOM.render(<WindPanel/>, document.getElementById('windpanel'));
ReactDOM.render(<PressureDiff/>, document.getElementById('pressurediff'));
ReactDOM.render(<TAFWidget station='KSFO'/>, document.getElementById('taf'));
