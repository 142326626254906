export async function Latest(provider: string, station: string) {
  const url = new URL(process.env.DAWGWIND_URL
                      + '/provider/'
                      + provider
                      + '/station/'
                      + station
                      + '/latest');

  try {
    const response = await fetch(url.toString(), {
      headers: {'X-API-Key': process.env.DAWGWIND_API_KEY},
    });
    return new WindReport(await response.json());
  } catch (error) {
    console.error(error);
    return new WindReport({provider, station});
  }
}

export async function Forecast(provider: string, station: string) {
  const url = new URL(process.env.DAWGWIND_URL
                      + '/provider/'
                      + provider
                      + '/station/'
                      + station
                      + '/forecast');

  try {
    const response = await fetch(url.toString(), {
      headers: {'X-API-Key': process.env.DAWGWIND_API_KEY},
    });

    const reports = [];
    for (const r of await response.json()) {
      reports.push(new WindReport(r));
    }
    return reports;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export class WindReport {
  valid: boolean;
  time: number;
  station: string;
  provider: string;
  windMph: number;
  windDeg: number;
  gustMph: number;
  tempF: number;
  dewpF: number;
  pressureInhg: number;
  relhumid: number;
  stationLink: string;

  constructor(report: any) {
    this.time = report.time;
    this.station = report.station;
    this.provider = report.provider;
    this.windMph = report.wind_mph;
    this.windDeg = report.wind_deg;
    this.gustMph = report.gust_mph;
    this.tempF = report.temp_f;
    this.dewpF = report.dewp_f;
    this.pressureInhg = report.pressure_inhg;
    this.relhumid = report.relhumid;
    this.stationLink = report.station_link;
    this.valid = this.isCromulent();
  }

  isCromulent(): boolean {
    return (typeof this.station === 'string' &&
            typeof this.provider === 'string' &&
            typeof this.time === 'number');
  }

  isStale(): boolean {
    return this.isCromulent() && this.ageMins > 120;
  }

  get windDir(): string {
    const dirs = 'N NNE NE ENE E ESE SE SSE S SSW SW WSW W WNW NW NNW N'.split(' ');
    const dir = dirs[Math.round(this.windDeg / 22.5)];
    return typeof dir === 'undefined' ? '' : dir;
  }

  get ageMins(): number {
    const now = Math.floor((new Date()).getTime() / 1000);
    const age = now - this.time;
    const mins = Math.floor(age / 60);
    return mins;
  }

  get age(): string {
    const hrs = Math.floor(this.ageMins / 60);
    const days = Math.floor(hrs / 24);
    const mins = this.ageMins - hrs * 60;
    if (hrs >= 100) {
      return days + 'd';
    }
    if (hrs > 0) {
      return hrs + 'h' + pad('00', mins.toString(), true) + 'm';
    }
    return mins + 'm';
  }
}

export function pad(padding: string, str: string, padLeft: boolean) {
  if (typeof str === 'undefined') {
    return padding;
  }
  if (padLeft) {
    return (padding + str).slice(-padding.length);
  } else {
    return (str + padding).substring(0, padding.length);
  }
}
