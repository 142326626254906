import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Latest, WindReport } from './DawgWind.tsx';

@observer export default class PressureDiff extends React.Component<{}, {}> {
  @observable loadingSAC = true;
  @observable loadingSFO = true;
  @observable reportSAC: WindReport;
  @observable reportSFO: WindReport;

  constructor(props: any) {
    super(props);

    this.loadingSAC = true;
    this.loadingSFO = true;

    Latest('mesowest', 'KSFO').then((val) => {
      runInAction(() => {
        this.loadingSFO = false;
        this.reportSFO = val;
      });
    }).catch((err) => {
      this.loadingSFO = false;
    });

    Latest('mesowest', 'KSAC').then((val) => {
      runInAction(() => {
        this.loadingSAC = false;
        this.reportSAC = val;
      });
    }).catch((err) => {
      this.loadingSAC = false;
    });
  }

  render() {
    if (this.loadingSAC || this.loadingSFO) {
      return <pre><div style={{color: '#888888'}}>SAC-SFO: loading...</div></pre>;
    }

    if (this.reportSAC === null
        || typeof this.reportSAC === 'undefined'
        || !this.reportSAC.valid
        || this.reportSFO === null
        || typeof this.reportSFO === 'undefined'
        || !this.reportSFO.valid) {
      return <pre><div style={{color: '#ff0000'}}>SAC-SFO: fail</div></pre>;
    }

    return <pre>SAC-SFO: {(this.reportSAC.pressureInhg - this.reportSFO.pressureInhg).toFixed(3)}</pre>;
  }
}
