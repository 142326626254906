import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as moment from 'moment-timezone';
import * as React from 'react';

import { Forecast, WindReport } from './DawgWind.tsx';
import './TAF.scss';

interface ITAFWidgetProps {
  station: string;
}

@observer export default class TAFWidget extends React.Component<ITAFWidgetProps, {}> {
  @observable reports: WindReport[] = [];
  @observable loading = true;

  constructor(props: any) {
    super(props);
    this.loading = true;
    this.fetchForecast();
  }

  @action async fetchForecast() {
    try {
      const reports = await Forecast('taf', this.props.station);
      this.loading = false;
      this.reports = reports;
    } catch (err) {
      this.loading = false;
    }
  }

  render() {
    if (this.loading) {
      return (
          <div className='tafwidget'>
          <div className={'tafwidget-item tafwidget-title'}>
          <a href={'https://tgftp.nws.noaa.gov/data/forecasts/taf/stations/' + this.props.station + '.TXT'}>
          {this.props.station} Forecast
          </a>
          </div>
          <div className={'tafwidget-header tafwidget-item'}>Date</div>
          <div className={'tafwidget-header tafwidget-item'}>Time</div>
          <div className={'tafwidget-header tafwidget-item'}>Dir</div>
          <div className={'tafwidget-header tafwidget-item'}>Sp</div>
          <div className={'tafwidget-header tafwidget-item'}>Gu</div>
          <div className={'tafwidget-rule'} />
          <div className={'tafwidget tafwidget-loading'}>Loading...</div>
          </div>
      );
    }

    if (this.reports === null || typeof this.reports === 'undefined') {
      return (
          <div className='tafwidget'>
          <div className={'tafwidget-item tafwidget-title'}>
          <a href={'https://tgftp.nws.noaa.gov/data/forecasts/taf/stations/' + this.props.station + '.TXT'}>
          {this.props.station} Forecast
          </a>
          </div>
          <div className={'tafwidget-header tafwidget-item'}>Date</div>
          <div className={'tafwidget-header tafwidget-item'}>Time</div>
          <div className={'tafwidget-header tafwidget-item'}>Dir</div>
          <div className={'tafwidget-header tafwidget-item'}>Sp</div>
          <div className={'tafwidget-header tafwidget-item'}>Gu</div>
          <div className={'tafwidget-rule'} />
          <div className={'tafwidget tafwidget-error'}>Error Loading Forecast</div>
          </div>
      );
    }

    const divs = [
      (
       <div className={'tafwidget-item tafwidget-title'}>
       <a href={'https://tgftp.nws.noaa.gov/data/forecasts/taf/stations/' + this.props.station + '.TXT'}>
       {this.props.station} Forecast
       </a>
       </div>
      ),
      <div key='1' className={'tafwidget-header tafwidget-item'}>Date</div>,
      <div key='2' className={'tafwidget-header tafwidget-item tafwidget-time'}>Time</div>,
      <div key='3' className={'tafwidget-header tafwidget-item tafwidget-dir'}>Dir</div>,
      <div key='4' className={'tafwidget-header tafwidget-item tafwidget-sp'}>Sp</div>,
      <div key='5' className={'tafwidget-header tafwidget-item tafwidget-sp'}>Gu</div>,
      <div key='6' className={'tafwidget-rule'} />,
    ];

    for (const report of this.reports) {
      const m = moment.unix(report.time).tz('America/Los_Angeles');
      const ts1 = m.format('dd MM/DD');
      const ts2 = m.format('h:mm A');
      const dir = report.windDir;
      const windMph = typeof report.windMph === 'undefined' ? '' : Math.round(report.windMph).toString();
      const gustMph = typeof report.gustMph === 'undefined' ? '' : Math.round(report.gustMph).toString();

      divs.push(<div className={'tafwidget-item'}>{ts1}</div>);
      divs.push(<div className={'tafwidget-item tafwidget-time'}>{ts2}</div>);
      divs.push(<div className={'tafwidget-item tafwidget-dir'}>{dir}</div>);
      divs.push(<div className={'tafwidget-item tafwidget-sp'}>{windMph}</div>);
      divs.push(<div className={'tafwidget-item tafwidget-sp'}>{gustMph}</div>);
    }
    return  <div className={'tafwidget'}>{divs}</div>;
  }
}
